import React from "react"
import Select from "react-select"
import _ from "lodash"

function generateNumberArray(start, end, step) {
  const result = [];
  for (let i = start; i <= end; i += step) {
      result.push(Number(i.toFixed(10))); // Use toFixed to avoid floating-point precision issues
  }
   return result
}

export const DurationSelect = props => {
  const { field, handleChange, id, disabled } = props
  const value = props.value
  //const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]
  const hours =  generateNumberArray(0, 15, 0.25)
  const selectOptions = hours.map(h => ({ value: h, label: `${h.toFixed(2)} Hrs`}))

  const options = {
    className: "psrs-select",
    classNamePrefix: "psrs",
    disabled: disabled || false,
    isClearable: true,
    isMulti: false,
    options: selectOptions,
    onChange: val => {
      //console.log(field, val);
      handleChange({ ...props, field, value: val.value, id })
      return val
    },
    defaultValue: _.find(selectOptions, {value: value}),
    placeholder: "Select Hrs"
  }
  return <Select {...options} />
}

export default DurationSelect
