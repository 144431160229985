import React, { useMemo, useState, useEffect } from "react"
import { Cascader } from "antd"
import eql from "deep-eql"



export const ShortDayFields = props => {
  const { handleChange, disabled, id } = props
  const firstOrSecond = props.firstOrSecond || "first"
  const placeholder = `${firstOrSecond === "first" ? "1st" : "2nd"} Short / Off Day`

  const value = useMemo(() => {
    const newValue = [
      props.value ? `${props.value[`${firstOrSecond}ShortDayWeek`]}` : undefined,
      props.value ? `${props.value[`${firstOrSecond}ShortDay`]}` : undefined,
      props.value ? `${props.value[`${firstOrSecond}ShortDayDuration`]}` : undefined,
      props.value ? `${props.value[`${firstOrSecond}ShortDayTime`]}` : undefined,
    ]
    return newValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  // console.log(value)

  const [state, setState] = useState({
    selected: value ? [...value] : undefined,
  })

  // console.log(
  //   `${props.value[`${firstOrSecond}ShortDayWeek`]}`,
  //   `${props.value[`${firstOrSecond}ShortDay`]}`,
  //   `${props.value[`${firstOrSecond}ShortDayDuration`]}`,
  //   props.value[`${firstOrSecond}ShortDayTime`]
  // )

  const times = useMemo(() => {
    return [
      { value: "early", label: "Early"},
      { value: "late", label: "Late"},
      { value: "off", label: "Off"},
    ]
  }, [])
  
  /*
  const durations = useMemo(() => {
    return [
      { value: "1", label: "0 hours", children: times },
      { value: "4", label: "4 hours", children: times },
      { value: "5", label: "5 hours", children: times },
      { value: "6", label: "6 hours", children: times },
      { value: "7", label: "7 hours", children: times },
      { value: "8", label: "8 hours", children: times },
      { value: "9", label: "9 hours", children: times },
      { value: "10", label: "10 hours", children: times },
      { value: "11", label: "11 hours", children: times },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  */
  
  const durations = useMemo(() => {
    return [
      { value: "1", label: "0 hours", children: times },
      { value: "4", label: "4.00 hours", children: times },
      { value: "4.25", label: "4.25 hours", children: times },
      { value: "4.5", label: "4.50 hours", children: times },
      { value: "4.75", label: "4.75 hours", children: times },
      { value: "5", label: "5.00 hours", children: times },
      { value: "5.25", label: "5.25 hours", children: times },
      { value: "5.5", label: "5.50 hours", children: times },
      { value: "5.75", label: "5.75 hours", children: times },
      { value: "6", label: "6.00 hours", children: times },
      { value: "6.25", label: "6.25 hours", children: times },
      { value: "6.5", label: "6.50 hours", children: times },
      { value: "6.75", label: "6.75 hours", children: times },
      { value: "7", label: "7.00 hours", children: times },
      { value: "7.25", label: "7.25 hours", children: times },
      { value: "7.5", label: "7.50 hours", children: times },
      { value: "7.75", label: "7.75 hours", children: times },
      { value: "8", label: "8.00 hours", children: times },
      { value: "8.25", label: "8.25 hours", children: times },
      { value: "8.5", label: "8.50 hours", children: times },
      { value: "8.75", label: "8.75 hours", children: times },
      { value: "9", label: "9.00 hours", children: times },
      { value: "9.25", label: "9.25 hours", children: times },
      { value: "9.5", label: "9.50 hours", children: times },
      { value: "9.75", label: "9.75 hours", children: times },
      { value: "10", label: "10.00 hours", children: times },
      { value: "10.25", label: "10.25 hours", children: times },
      { value: "10.5", label: "10.50 hours", children: times },
      { value: "10.75", label: "10.75 hours", children: times },
      { value: "11", label: "11 hours", children: times },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  

  const days = useMemo(() => {
    return [
      { value: null, label: " --- ", children: durations },
      { value: "0", label: "Sun", children: durations },
      { value: "1", label: "Mon", children: durations },
      { value: "2", label: "Tue", children: durations },
      { value: "3", label: "Wed", children: durations },
      { value: "4", label: "Thu", children: durations },
      { value: "5", label: "Fri", children: durations },
      { value: "6", label: "Sat", children: durations },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = sel => {
    setState({
      ...state,
      selected: sel,
    })
    //console.log("onChange sel, state", sel, state)
  }

  useEffect(() => {
    const update = {}
    update[`${firstOrSecond}ShortDayWeek`] = parseInt(state.selected[0])
    update[`${firstOrSecond}ShortDay`] = parseInt(state.selected[1])
    //update[`${firstOrSecond}ShortDayDuration`] = parseInt(state.selected[2])
    update[`${firstOrSecond}ShortDayDuration`] = Number(state.selected[2]).toFixed(10)
    update[`${firstOrSecond}ShortDayTime`] = state.selected[3]

    if (!eql(state.selected, value)) {
      // console.log("USE EFFECT UPDATE", state.selected, value)
      handleChange({ ...props, field: false, value: update, id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selected])

  const options = {
    disabled: disabled ? true : false,
    options: [
      { value: "1", label: "1st", children: days },
      { value: "2", label: "2nd", children: days },
    ],
    fieldNames: { label: "label", value: "value", children: "children" },
    allowClear: true,
    changeOnSelect: false,
    showSearch: false,
    expandTrigger: "click",
    popupPlacement: "bottomLeft",
    placeholder: placeholder,
    size: "default",
    displayRender: label => label.join(" "),
    onChange: handleSelect,
    value: state.selected[0] !== "null" ? state.selected : null,
  }

  return (
    <div className="short_day_selections">
      <Cascader data-cy="short-day-select" {...options} />
    </div>
  )
}

export default ShortDayFields
